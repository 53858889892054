import React from 'react'
import './electrical.css'

import elecSubteam from './img/roster2023/CEV_Electrical.jpg'
import cAndrew from './img/roster2023/andrew_cai.jpg'
import rDaniel from './img/roster2023/daniel_rodriguiz.jpg'
import bBhadra from './img/roster2023/bhadra_bejoy.jpg'
import kYoon from './img/roster2023/empty-frame.png'
import gKunal from './img/roster2023/kunal_gupta.jpg'
import pDionna from './img/roster2023/dionna_parina.jpg'
import kAri from './img/roster2023/ari_kapelyan.jpg'
import tDaniela from './img/roster2023/daniela_tran.jpg'
import sArnav from './img/roster2023/arnav_shah.jpg'
import lEdward from './img/roster2023/edward_lee.jpg'
import fMicah from './img/roster2023/micah_fisher.jpg'
import aRachel from './img/roster2023/rachel_arena.jpg'
import frame from './img/roster2023/empty-frame.png'


function Electrical() {
    return (
        <div class="electrical-background">
            <div class="electrical-intro">
                Electrical
                <br />
                <div id="space"></div>
                <img alt="electrical" src={elecSubteam} />
                <div id="space"></div>
            </div>

            <div class="electrical-overview">
                The electrical subteam is in charge of powering the vehicle, controlling the
                motor, monitoring safety parameters, and performing real-time data
                acquisition and visualization to inform vehicle design as well as racing strategy.
            </div>

            <div class="electrical-projects">
                <div class="electrical-side-project">
                    <h4> </h4>
                    <h2>Safety System</h2>
                    <p>A new subsystem that controls smart-lighting, blind spot detection, auto-braking, and windshield-wiping for our Urban Concept vehicle.</p>
                </div>
                <div class="electrical-side-project">
                    <h4> </h4>
                    <h2>Battery Management System</h2>
                    <p>Provides safety for the vehicle by monitoring conditions outside of the 24V battery’s safe operating limits and providing isolation in the case of dangerous conditions.</p>
                </div>
                {/* <div class="electrical-side-project">
                    <h4> </h4>
                    <h2>Data Acquisition System</h2>
                    <p>BLURB HERE</p>
                </div> */}

                <div class="electrical-side-project">
                    <h2>Automation</h2>
                    <p>Electrically actuates the steering, throttle, and braking of the car to allow software’s autonomy team to control the vehicle.</p>
                </div>
                <div class="electrical-side-project">
                    <h4> </h4>
                    <h2>Remote Control</h2>
                    <p>Focuses on controlling the car’s steering, braking, and throttle through wireless means for easier testing.
                        Will make a custom remote to control the car.</p>
                </div>
                <div class="electrical-side-project">
                    <h2>Power Converter</h2>
                    <p>Creates highly efficient DC-DC converters that convert our battery voltage to lower voltages used by other systems on the car.</p>
                </div>
                <div class="electrical-side-project">
                    <h2>Motor Controller</h2>
                    <p>Drives and supplies current to our car's brushless DC motor through a motor control algorithm to operate the motor as efficiently as possible.</p>
                </div>
                <div class="electrical-side-project">
                    <h4> </h4>
                    <h2>Data Acquisition System</h2>
                    <p>
                        Responsible for collecting data from sensors in the car. Saves and sends data to the driver dashboard and team database to be analyzed.
                    </p>
                </div>

            </div>
            <div class="electrical-leads">
                <div class="electrical-lead">
                    <div class="photo"><img alt="member" src={gKunal} class='image' /></div>
                    <div class='electrical-middle'>
                        <div class='electrical-funfact'>Former World #2 'Kunal' in Formula 1 Fantasy League</div>
                    </div>
                    <div class="electrical-about">
                        <div class="electrical-name">Kunal Gupta</div>
                        <div class="electrical-title">Full Team Lead</div>
                        <div class="electrical-info">Computer Science<br />2023</div>
                    </div>
                </div>
                <div class="electrical-lead">
                    <div class="photo"><img alt="member" src={cAndrew} class='image' /></div>
                    <div class='electrical-middle'>
                        <div class='electrical-funfact'>I peel my poached eggs and eat sandwiches in series.</div>
                    </div>
                    <div class="electrical-about">
                        <div class="electrical-name">Andrew Cai</div>
                        <div class="electrical-title">Subteam Lead</div>
                        <div class="electrical-info">Electrical and Computer Engineering<br />2023</div>

                    </div>
                </div>
            </div>



            <div class="electrical-subteam">

                <div class="electrical-members">

                    <div class="electrical-member">
                        <div class="photo"><img alt="member" src={bBhadra} class='image' /></div>
                        <div class='electrical-middle'>
                            <div class='electrical-funfact'>I like sewing and making my own clothes.</div>
                        </div>
                        <div class="electrical-about">
                            <div class="electrical-name">Bhadra Bejoy</div>
                            <div class="electrical-info">Electrical and Computer Engineering<br />2024</div>
                        </div>
                    </div>

                    <div class="electrical-member">
                        <div class="photo"><img alt="member" src={rDaniel} class='image' /></div>
                        <div class='electrical-middle'>
                            <div class='electrical-funfact'>Better at Formula 1 Fantasy than the former World #2 'Kunal'</div>
                        </div>
                        <div class="electrical-about">
                            <div class="electrical-name">Daniel Rodriguez</div>
                            <div class="electrical-info">Electrical and Computer Engineering<br />2024</div>
                        </div>
                    </div>

                    <div class="electrical-member">
                        <div class="photo"><img alt="member" src={kYoon} class='image' /></div>
                        <div class='electrical-middle'>
                            <div class='electrical-funfact'></div>
                        </div>
                        <div class="electrical-about">
                            <div class="electrical-name">Yoon Kang</div>
                            <div class="electrical-info">Electrical and Computer Engineering<br />2024</div>
                        </div>
                    </div>
                    <div class="electrical-member">
                        <div class="photo"><img alt="member" src={pDionna} class='image' /></div>
                        <div class='electrical-middle'>
                            <div class='electrical-funfact'>I'm a student manager of the football team here!</div>
                        </div>
                        <div class="electrical-about">
                            <div class="electrical-name">Dionna Parina</div>
                            <div class="electrical-info">Electrical and Computer Engineering<br />2025</div>
                        </div>
                    </div>
                    <div class="electrical-member">
                        <div class="photo"><img alt="member" src={kAri} class='image' /></div>
                        <div class='electrical-middle'>
                            <div class='electrical-funfact'>I can speak 4 languages.</div>
                        </div>
                        <div class="electrical-about">
                            <div class="electrical-name">Ari Kapelyan</div>
                            <div class="electrical-info">Electrical and Computer Engineering<br />2025</div>
                        </div>
                    </div>
                    <div class="electrical-member">
                        <div class="photo"><img alt="member" src={tDaniela} class='image' /></div>
                        <div class='electrical-middle'>
                            <div class='electrical-funfact'>I used to have pet chickens.</div>
                        </div>
                        <div class="electrical-about">
                            <div class="electrical-name">Daniela Tran</div>
                            <div class="electrical-info">Electrical and Computer Engineering<br />2025</div>
                        </div>
                    </div>
                    <div class="electrical-member">
                        <div class="photo"><img alt="member" src={sArnav} class='image' /></div>
                        <div class='electrical-middle'>
                            <div class='electrical-funfact'></div>
                        </div>
                        <div class="electrical-about">
                            <div class="electrical-name">Arnav Shah</div>
                            <div class="electrical-info">Electrical and Computer Engineering<br />2026</div>
                        </div>
                    </div>
                    <div class="electrical-member">
                        <div class="photo"><img alt="member" src={lEdward} class='image' /></div>
                        <div class='electrical-middle'>
                            <div class='electrical-funfact'>I published my own instrumental album on Soundcloud.</div>
                        </div>
                        <div class="electrical-about">
                            <div class="electrical-name">Edward Lee</div>
                            <div class="electrical-info">Electrical and Computer Engineering<br />2026</div>
                        </div>
                    </div>
                    <div class="electrical-member">
                        <div class="photo"><img alt="member" src={fMicah} class='image' /></div>
                        <div class='electrical-middle'>
                            <div class='electrical-funfact'></div>
                        </div>
                        <div class="electrical-about">
                            <div class="electrical-name">Micah Fisher</div>
                            <div class="electrical-info">Electrical and Computer Engineering<br />2026</div>
                        </div>
                    </div>
                    <div class="electrical-member">
                        <div class="photo"><img alt="member" src={aRachel} class='image' /></div>
                        <div class='electrical-middle'>
                            <div class='electrical-funfact'>I've never met a kitchen appliance I didn't want to take apart.</div>
                        </div>
                        <div class="electrical-about">
                            <div class="electrical-name">Rachel Arena</div>
                            <div class="electrical-info">Electrical and Computer Engineering<br />2026</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Electrical
