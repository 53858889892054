import React from 'react'
import './App.css'
// import NavBar from './components/NavBar'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import Home from './components/HomeNew'
import Competition from './components/Competition'
import Team from './components/Team'
import Vehicles from './components/Vehicles'
import Sponsors from './components/Sponsors'
import Recruitment from './components/Recruitment'
import BottomInfo from './components/BottomInfo'
import Leads from './components/Leads'
import Software from './components/Software'
import Mechanical from './components/Mechanical'
import Electrical from './components/Electrical'
import Operations from './components/Operations'
import Platform from './components/Platform'
import ScrollToTop from './components/ScrollToTop'

function App() {
  return (
    <Router>
      <ScrollToTop>
        <div className="App">
          {/* <NavBar /> */}
          <Switch>
            {/* <Route path="/" exact component={Main} /> */}
            <Route path="/" exact component={Home} />
            <Route path="/team" exact component={Team} />
            <Route path="/team/leads" exact component={Leads} />
            <Route path="/team/software" exact component={Software} />
            <Route path="/team/mechanical" exact component={Mechanical} />
            <Route path="/team/electrical" exact component={Electrical} />
            <Route path="/team/operations" exact component={Operations} />
            <Route path="/team/platform" exact component={Platform} />
            <Route path="/competition" exact component={Competition} />
            <Route path="/vehicles" exact component={Vehicles} />
            <Route path="/recruitment" exact component={Recruitment} />
            <Route path="/sponsors" exact component={Sponsors} />
          </Switch>
          {/* <BottomInfo /> */}
        </div>
      </ScrollToTop>
    </Router>
  );
}

export default App;
