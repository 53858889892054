import React from 'react'
import './leads.css'
import fSofia from './img/roster2023/sofia_florez.jpg'
import sKate from './img/roster2023/kate_sawin.jpg'
import cDerek from './img/roster2023/derek_chiou.jpg'
import wAlejandro from './img/roster2023/alejandro_wilcox.jpg'
import kMax from './img/roster2023/max_koenig.jpg'
import gKunal from './img/roster2023/kunal_gupta.jpg'
import cAndrew from './img/roster2023/andrew_cai.jpg'
import dVivian from './img/roster2023/vivian_dai.jpg'
import mRachel from './img/roster2023/rachel_mead.jpg'


function Leads() {
    return (
        <div className="leads-background">
            <div class="leads-intro">
                Leads
                {/* <br /> */}
                <div id="space"></div>
                {/* <img alt="operations subteam" src={opsSubteam} /> */}
                <div id="space"></div>
            </div>

            {/* <div class="leads-overview">
                Loream ipsum blah blah blah
            </div> */}

            <div class="team-leads">
                <div class="team-lead">
                    <div class="photo"><img alt="member" src={fSofia} class='image' /></div>
                    <div class='lead-middle'>
                        <div class='lead-funfact'> </div>
                    </div>
                    <div class="lead-about">
                        <div class="lead-name">Sofia Florez</div>
                        <div class="lead-title">Full Team Lead</div>
                        <div class="lead-info">Operations Research<br />2023</div>
                    </div>
                </div>

                <div class="team-lead">
                    <div class="photo"><img alt="member" src={cDerek} class='image' /></div>
                    <div class='lead-middle'>
                        <div class='lead-funfact'></div>
                    </div>
                    <div class="lead-about">
                        <div class="lead-name">Derek  Chiou</div>
                        <div class="lead-title">Full Team Lead</div>
                        <div class="lead-info">Mechanical and Aerospace Engineering<br />2023</div>
                    </div>
                </div>

                <div class="team-lead">
                    <div class="photo"><img alt="member" src={mRachel} class='image' /></div>
                    <div class='lead-middle'>
                        <div class='lead-funfact'></div>
                    </div>
                    <div class="lead-about">
                        <div class="lead-name">Rachel Mead</div>
                        <div class="lead-title">Safety Manager</div>
                        <div class="lead-info">Mechanical and Aerospace Engineering<br />2023</div>
                    </div>
                </div>
            </div>
            {/* SUBTEAM LEADS ********************* */}
            <div class="leads-subleads">
                <div class="subteam-leads">

                    <div class="subteam-lead">
                        <div class="photo"><img alt="member" src={wAlejandro} class='image' /></div>
                        <div class='lead-middle'>
                            <div class='lead-funfact'></div>
                        </div>
                        <div class="lead-about">
                            <div class="lead-name">Alejandro Wilcox</div>
                            <div class="lead-title">Mechanical Lead</div>
                            <div class="lead-info">Mechanical and Aerospace Engineering<br />2023</div>
                        </div>
                    </div>

                    <div class="subteam-lead">
                        <div class="photo"><img alt="member" src={kMax} class='image' /></div>
                        <div class='lead-middle'>
                            <div class='lead-funfact'></div>
                        </div>
                        <div class="lead-about">
                            <div class="lead-name">Max Koenig</div>
                            <div class="lead-title">Mechanical Lead</div>
                            <div class="lead-info">Mechanical and Aerospace Engineering<br />2023</div>
                        </div>
                    </div>

                    <div class="subteam-lead">
                        <div class="photo"><img alt="member" src={cAndrew} class='image' /></div>
                        <div class='lead-middle'>
                            <div class='lead-funfact'></div>
                        </div>
                        <div class="lead-about">
                            <div class="lead-name">Andrew Cai</div>
                            <div class="lead-title">Electrical Lead</div>
                            <div class="lead-info">Electrical and Computer Engineering<br />2023</div>
                        </div>
                    </div>

                    <div class="subteam-lead">
                        <div class="photo"><img alt="member" src={dVivian} class='image' /></div>
                        <div class='lead-middle'>
                            <div class='lead-funfact'></div>
                        </div>
                        <div class="lead-about">
                            <div class="lead-name">Vivian Dai</div>
                            <div class="lead-title">Software Lead</div>
                            <div class="lead-info">Computer Science<br />2023</div>
                        </div>
                    </div>

                    <div class="subteam-lead">
                        <div class="photo"><img alt="member" src={gKunal} class='image' /></div>
                        <div class='lead-middle'>
                            <div class='lead-funfact'></div>
                        </div>
                        <div class="lead-about">
                            <div class="lead-name">Kunal Gupta</div>
                            <div class="lead-title">Electrical Lead</div>
                            <div class="lead-info">Electrical and Computer Engineering/Computer Science<br />2024</div>
                        </div>
                    </div>

                    <div class="subteam-lead">
                        <div class="photo"><img alt="member" src={sKate} class='image' /></div>
                        <div class='lead-middle'>
                            <div class='lead-funfact'></div>
                        </div>
                        <div class="lead-about">
                            <div class="lead-name">Kate Sawin</div>
                            <div class="lead-title">Operations Lead</div>
                            <div class="lead-info">Operations Research<br />2024</div>
                        </div>
                    </div>

                    
                </div>
            </div>
        </div>
    )
}

export default Leads
