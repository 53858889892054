import React from 'react'
import './recruitment.css'
import upper from './img/recruitment/UpperclassmenTimeline.png'
import spring from './img/recruitment/CEVsponsorshiprecruitmentflyer.png'
import timeline from './img/recruitment/FreshmenWebsiteTimeline.png'

function Recruitment() {
    return (
        <div className="background">
            <div id="thanks">
                <strong>Applications for Fall 2023 are now open!</strong>
            </div>

            <div id="infosessions">
                <div id="infoblurb">
                    Check out our info session dates and the application process below!
                </div>
                <div id = "info_session_title">
                    INFO SESSIONS:
                </div>
                <li>9/7/23 from 5-6 PM @ Phillips 219</li>
                <li>9/12/23 from 5-6 PM @ Phillips 219</li>
                <li>9/21/23 from 5-6 PM @ Phillips 219</li>
                <li>9/25/23 from 5-6 PM @ RPCC 205</li>

                <div id = "timeline">
                    APPLICATION DUE DATES:
                </div>
                <li>Upperclassmen applications due: 8/31 @ 11:59 PM</li>
                <li>First Years & Transfers due: 9/21 @ 11:59 PM</li>

            </div>

            <div id = "apps">
                <p><a href="https://forms.gle/nK7Aua5AT6jiWBhe7" target="_blank" rel="noopener noreferrer" >CEV Upperclassmen Recruitment Form</a></p>
                <p><a href="https://forms.gle/TvNRCAEvjiF8CTFy6" target="_blank" rel="noopener noreferrer" >CEV First Years & Transfers Recruitment Form</a></p>
            </div>

            {/* <div className="coffeeChats">
                <strong>Coffee Chats</strong>
            </div>
            <div className="recruitment-row">
                <div className="recruitment-column">
                    Come talk to our subteam leads about CEV! We would love to meet you and hear more about your interests.
                    <p>To meet a lead, sign up for a time on their calendly:</p>
                </div>
                <div className="recruitment-column-right">
                    <p><a href="https://calendly.com/sf444/cev-coffee-chat-sofia-florez" target="_blank" rel="noopener noreferrer" >Sofia Florez (Full Team Lead)</a></p>
                    <p><a href="https://calendly.com/dhc224" target="_blank" rel="noopener noreferrer" >Derek Chiou (Full Team Lead)</a></p>
                    <p><a href="https://calendly.com/ayc57/cev-coffee-chat?month=2022-09&date=2022-09-19" target="_blank" rel="noopener noreferrer" >Andrew Cai (Electrical Lead)</a></p>
                    <p><a href="https://calendly.com/kg379/cev-coffee-chat-kunal-gupta-electrical-lead" target="_blank" rel="noopener noreferrer" >Kunal Gupta (Electrical Lead)</a></p>
                    <p><a href="https://calendly.com/alejandrowilcox/ajw248" target="_blank" rel="noopener noreferrer" >Alejandro Wilcox (Mechanical Lead)</a></p>
                    <p><a href="https://calendly.com/mjk385/cev-coffee-chat" target="_blank" rel="noopener noreferrer" >Max Koenig (Mechanical Lead)</a></p>
                    <p><a href="https://calendly.com/viviandai/cev-coffee-chats" target="_blank" rel="noopener noreferrer" >Vivian Dai (Software Lead)</a></p>
                    <p><a href="https://calendly.com/khs72/cev-coffee-chats-kate" target="_blank" rel="noopener noreferrer" >Kate Sawin (Operations Lead)</a></p>
                </div>
            </div> */}
            {/* <div id="info">
                <font size="6.5"><strong></strong></font>
                <font size="6.5"><strong>First Year/Transfer Recruitment Timeline</strong></font>

            </div>
            <div id="info">
                <img src={timeline} alt="timeline" />
                <hr></hr>
            </div> */}

            <div id="promo-vid1">
                <div id="info">
                    Recruitment Video
                </div>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/Nf2YCHuA7MQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div id="zoom-vid1">
                <div id="info">
                    Past Info Session Recording
                </div>
                <iframe width="672" height="378" src="https://www.youtube.com/embed/vTtfSD0sd10" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div id="zoom-vid2">
                <div id="info">
                    Past Info Session Recording
                </div>
                <iframe width="374" height="210" src="https://www.youtube.com/embed/vTtfSD0sd10" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div id="info">
                <hr></hr>
            </div>
            <div id="info">
                Check out our socials to stay updated about the team!
            </div>
            <div id="page1">
                <iframe title="Cornell Electric Vehicles Facebook" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FCornellElectricVehicles%2F&tabs=timeline&width=500&height=650&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="500" height="650" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
            </div>
            <div id="page2">
                <iframe title="Cornell Electric Vehicles Facebook" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FCornellElectricVehicles%2F&tabs=timeline&width=300&height=600&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="300" height="400" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
            </div>
        </div>
    )
}

export default Recruitment
