import React from 'react'
import './sponsors.css'
import easycomp from './img/sponsors/sponsor-easycomp.png'
import wanan from './img/sponsors/sponsor-wanan.png'
import ccmi from './img/sponsors/sponsor-ccmi.png'
import soe from './img/sponsors/sponsor-soe.png'
import lutron from './img/sponsors/sponsor-lutron.jpeg'
import elegoo from './img/sponsors/sponsor-elegoo.png'
import siglent from './img/sponsors/sponsor-siglent.png'
import fibreglast from './img/sponsors/sponsor-fibreglast.png'
import smidgens from './img/sponsors/sponsor-smidgens.png'
import gridconnect from './img/sponsors/sponsor-gridconnect.png'
import altium from './img/sponsors/sponsor-altium.png'
import ccb from './img/sponsors/sponsor-ccb.jpg'
import hakko from './img/sponsors/sponsor-hakko.png'
import lord from './img/sponsors/sponsor-lord.png'
import oshpark from './img/sponsors/sponsor-oshpark.png'
import nvidia from './img/sponsors/sponsor-nvidia.png'
import packetNEW from "./sponsorship-packet.pdf"

function Sponsors() {
    return (
        <div className="sponsors-background">
            <div className="sponsors-intro">
                <strong>Our Sponsors</strong>
            </div>

            <div className="sponsors-message">
                Our sponsors make what we do possible. CEV competes annually in the Shell Eco-Marathon.
                In 2019, we took 3rd place in the Battery Electric Prototype category. Currently,
                we are also aiming to develop an autonomous urban-concept vehicle. We hope you can join
                in our future efforts as we continue our work towards building the world's most energy
                efficient vehicle.
                <br /><br />
                <a href={packetNEW} target="_blank" rel="noopener noreferrer" >Sponsorship Packet</a> &nbsp; &nbsp; &nbsp; &nbsp;
                <a href="https://securelb.imodules.com/s/1717/giving/interior.aspx?sid=1717&gid=2&pgid=403&cid=1031&dids=288&sort=1&bledit=26" target="_blank" rel="noopener noreferrer" >Donate</a>
                <div className='sponsors-inquries'>For any sponsorship inquries email <a href="mailto: cornellresistance@gmail.com" target="_blank" rel="noopener noreferrer">cornellresistance@gmail.com</a>
                </div>
            </div>


            <div id="logoBackground">
                <div className="sponsors-logos">
                    <div className="sponsors-row">
                        <div className="sponsors-column">
                            <div className="comp">
                                <a href="https://siglentna.com/" target="_blank" rel="noopener noreferrer" ><img src={siglent} alt="Siglent" /></a>
                            </div>
                        </div>
                        <div className="sponsors-column">
                            <div className="comp_restrict">
                                <a href="https://www.easycomposites.co.uk/#!/" target="_blank" rel="noopener noreferrer" ><img src={easycomp} alt="Easy Composites" /></a>
                            </div>
                        </div>
                        <div className="sponsors-column">
                            <div className="comp_restrict">
                                <a href="https://www.ccmiplastics.com/" target="_blank" rel="noopener noreferrer" ><img src={ccmi} alt="CCMI Plastics" /></a>
                            </div>
                        </div>
                    </div>


                    <div className="sponsors-row">
                        <div className="sponsors-column">
                            <div className="comp_restrict">
                                <a href="http://www.tswanan.com/en/index" target="_blank" rel="noopener noreferrer" ><img src={wanan} alt="万安" /></a>
                            </div>
                        </div>
                        <div className="sponsors-column">
                            <div className="comp">
                                <a href="https://soeffects.com/" target="_blank" rel="noopener noreferrer" ><img src={soe} alt="SOE" /></a>
                            </div>
                        </div>
                        <div className="sponsors-column">
                            <div className="comp">
                                <a href="https://www.elegoo.com/" target="_blank" rel="noopener noreferrer" ><img src={elegoo} alt="Elegoo" /></a>
                            </div>
                        </div>
                    </div>

                    <div className="sponsors-row">
                        <div className="sponsors-column">
                            <div className="comp_restrict">
                                <a href="https://www.fibreglast.com/" target="_blank" rel="noopener noreferrer" ><img src={fibreglast} alt="Fibre Glast" /></a>
                            </div>
                        </div>
                        <div className="sponsors-column">
                            <div className="comp">
                                <a href="https://smidgensinc.com/" target="_blank" rel="noopener noreferrer" ><img src={smidgens} alt="Smidgens" /></a>
                            </div>
                        </div>
                        <div className="sponsors-column">
                            <div className="comp">
                                <a href="https://www.gridconnect.com/" target="_blank" rel="noopener noreferrer" ><img src={gridconnect} alt="Grid Connect" /></a>
                            </div>
                        </div>
                    </div>

                    <div className="sponsors-row">
                        <div className="sponsors-column">
                            <div className="comp_restrict">
                                <a href="https://www.altium.com/" target="_blank" rel="noopener noreferrer" ><img src={altium} alt="Altium" /></a>
                            </div>
                        </div>
                        <div className="sponsors-column">
                            <div className="comp">
                                <a href="https://www.customcircuitboards.com/" target="_blank" rel="noopener noreferrer" ><img src={ccb} alt="Custom Circuit Boards" /></a>
                            </div>
                        </div>
                        <div className="sponsors-column">
                            <div className="comp">
                                <a href="https://www.hakko.com/" target="_blank" rel="noopener noreferrer" ><img src={hakko} alt="Hakko" /></a>
                            </div>
                        </div>
                    </div>

                    <div className="sponsors-row">
                        <div className="sponsors-column">
                            <div className="comp_restrict">
                                <a href="https://www.microstrain.com/" target="_blank" rel="noopener noreferrer" ><img src={lord} alt="Lord" /></a>
                            </div>
                        </div>
                        <div className="sponsors-column">
                            <div className="comp">
                                <a href="https://www.nvidia.com/en-us/" target="_blank" rel="noopener noreferrer" ><img src={nvidia} alt="Nvidia" /></a>
                            </div>
                        </div>
                        <div className="sponsors-column">
                            <div className="comp">
                                <a href="https://oshpark.com/" target="_blank" rel="noopener noreferrer" ><img src={oshpark} alt="Oshpark" /></a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Sponsors
