import React from 'react'
import './software.css'
import softSubteam from './img/roster2023/CEV_Software.jpg'
import kAditya from './img/roster2023/aditya_kakade.jpg'
import hBryant from './img/roster2023/bryant_har.jpg'
import wDrew from './img/roster2023/drew_wilenzick.jpg'
import mEric from './img/roster2023/eric_marchetti.jpg'
import kJason from './img/roster2023/jason_klein.jpg'
import dKalehiwot from './img/roster2023/kalehiwot_dessalgne.jpg'
import kLauren from './img/roster2023/lauren_kam.jpg'
import hMedhi from './img/roster2023/empty-frame.png'
import mMihir from './img/roster2023/mihir_mishra.jpg'
import kPranav from './img/roster2023/empty-frame.png'
import cSia from './img/roster2023/sia_chitnis.jpg'
import dSerena from './img/roster2023/serena_duncan.jpg'
import dVivian from './img/roster2023/vivian_dai.jpg'
import aAdams from './img/roster2023/empty-frame.png'


// import oChuhan from './img/roster2023/chuhan_ouyang.jpg'
import frame from './img/roster2023/empty-frame.png'
import eColin from './img/roster2023/colin_erb.jpg'


function Software() {
    return (
        <div className="software-background">
            <div className="software-intro">
                Software
                <br />
                <div id="space"></div>
                <img src={softSubteam} alt="software subteam" />
                <div id="space"></div>
            </div>

            <div className="software-overview">
                The Software subteam is responsible for high frequency data collection,
                storage, and efficiency analysis during competition. The team is also
                working on object detection, classification, and path planning projects
                for future autonomous movement of the vehicle.
            </div>

            <div class="software-projects">
                <div class="software-side-project">
                    <h4> </h4>
                    <h2>Vision / Perception</h2>
                    <p>Detects and localizes objects around the vehicle using
                        cameras and various deep learning algorithms to prevent
                        collisions on the track.</p>
                </div>
                <div class="software-side-project">
                    <h4> </h4>
                    <h2>Dashboard</h2>
                    <p>Implements a web-based platform to display crucial
                        information about the vehicle in real-time to both the
                        driver and the team in the paddocks. This is used to analyze
                        data about the car during the competition.
                    </p>
                </div>
                <div class="software-side-project">
                    <h4> </h4>
                    <h2>Motion Planning / Localization </h2>
                    <p>Fuses inputs to accurately estimate the current pose of the
                        car calculate an efficient trajectory for the vehicle.
                        This project heavily involves algorithm development, tuning,
                        and simulation.
                    </p>
                </div>
                <div class="software-side-project">
                    <h4> </h4>
                    <h2>Optimization </h2>
                    <p>Uses information about the track and car to determine the
                        optimal driving line and optimal motor usage for the car
                        during the race.
                    </p>
                </div>
            </div>

            <div class="software-leads">
                <div class="software-lead">
                    <div class="photo"><img alt="member" src={dVivian} class='image' /></div>
                    <div class='software-middle'>
                        <div class='software-funfact'>I'm allergic to lobster.</div>
                    </div>
                    <div class="software-about">
                        <div class="software-name">Vivian Dai</div>
                        <div class="software-title">Subteam Lead</div>
                        <div class="software-info">Computer Science<br />2023</div>

                    </div>
                </div>
            </div>

            <div className="software-subteam">
                <div className="software-members">
                    <div className="software-member">
                        <div className="photo"><img alt="member" src={aAdams} class='image' /></div>
                        <div class='software-middle'>
                            <div class='software-funfact'>I prefer my ice cream melted.</div>
                        </div>
                        <div className="software-about">
                            <div className="software-name">Adams Anaglo</div>
                            <div className="software-info">Computer Science<br />2024</div>
                        </div>
                    </div>
                    <div className="software-member">
                        <div className="photo"><img alt="member" src={eColin} class='image' /></div>
                        <div class='software-middle'>
                            <div class='software-funfact'> . </div>
                        </div>
                        <div className="software-about">
                            <div className="software-name">Colin Erb</div>
                            <div className="software-info">Materials Science<br />2024</div>                            
                        </div>
                    </div>

                    <div className="software-member">
                        <div className="photo"><img alt="member" src={kPranav} class='image' /></div>
                        <div class='software-middle'>
                            <div class='software-funfact'>Despite the immense pressure placed on Pranav to submit a fun fact, he was unable to come up with one.</div>
                        </div>
                        <div className="software-about">
                            <div className="software-name">Pranav Kakhandiki</div>
                            <div className="software-info">Applied and Engineering Physics/<br />Computer Science<br />2024</div>
                        </div>
                    </div>
                    <div className="software-member">
                        <div className="photo"><img alt="member" src={kLauren} class='image' /></div>
                        <div class='software-middle'>
                            <div class='software-funfact'>I've never had McDonald's.</div>
                        </div>
                        <div className="software-about">
                            <div className="software-name">Lauren Kam</div>
                            <div className="software-info">Computer Science<br />2025</div>
                        </div>
                    </div>
                    <div className="software-member">
                        <div className="photo"><img alt="member" src={dKalehiwot} class='image' /></div>
                        <div class='software-middle'>
                            <div class='software-funfact'>I went snorkeling with Joe Biden.</div>
                        </div>
                        <div className="software-about">
                            <div className="software-name">Kalehiwot Dessalgne</div>
                            <div className="software-info">Computer Science<br />2025</div>
                        </div>
                    </div>
                    {/* <div className="software-member">
                        <div className="photo"><img alt="member" src={oChuhan} class='image' /></div>
                        <div class='software-middle'>
                            <div class='software-funfact'>I love baby pandas and have seen some!</div>
                        </div>
                        <div className="software-about">
                            <div className="software-name">Chuhan Ouyang</div>
                            <div className="software-info">Computer Science<br />2025</div>
                        </div>
                    </div> */}
                    <div className="software-member">
                        <div className="photo"><img alt="member" src={kAditya} class='image' /></div>
                        <div class='software-middle'>
                            <div class='software-funfact'> . </div>
                        </div>
                        <div className="software-about">
                            <div className="software-name">Aditya Kakade</div>
                            <div className="software-info">Computer Science<br />2025</div>

                        </div>
                    </div>
                    <div className="software-member">
                        <div className="photo"><img alt="member" src={hBryant} class='image' /></div>
                        <div class='software-middle'>
                            <div class='software-funfact'> . </div>
                        </div>
                        <div className="software-about">
                            <div className="software-name">Bryant Har</div>
                            <div className="software-info">Physics and Computer Science<br />2025</div>
                        </div>
                    </div>

                    <div className="software-member">
                        <div className="photo"><img alt="member" src={wDrew} class='image' /></div>
                        <div class='software-middle'>
                            <div class='software-funfact'> . </div>
                        </div>
                        <div className="software-about">
                            <div className="software-name">Drew Wilenzick</div>
                            <div className="software-info">Computer Science and ORIE<br />2026</div>

                        </div>
                    </div>
                    <div className="software-member">
                        <div className="photo"><img alt="member" src={mEric} class='image' /></div>
                        <div class='software-middle'>
                            <div class='software-funfact'> . </div>
                        </div>
                        <div className="software-about">
                            <div className="software-name">Eric Marchetti</div>
                            <div className="software-info">Computer Science<br />2026</div>
                        </div>
                    </div>
                    <div className="software-member">
                        <div className="photo"><img alt="member" src={kJason} class='image' /></div>
                        <div class='software-middle'>
                            <div class='software-funfact'> . </div>
                        </div>
                        <div className="software-about">
                            <div className="software-name">Jason Klein</div>
                            <div className="software-info">Computer Science<br />2026</div>
                        </div>
                    </div>
                    <div className="software-member">
                        <div className="photo"><img alt="member" src={hMedhi} class='image' /></div>
                        <div class='software-middle'>
                            <div class='software-funfact'> . </div>
                        </div>
                        <div className="software-about">
                            <div className="software-name">Medhi Heydari</div>
                            <div className="software-info">Computer Science<br />2026</div>
                        </div>
                    </div>
                    <div className="software-member">
                        <div className="photo"><img alt="member" src={mMihir} class='image' /></div>

                        <div class='software-middle'>
                            <div class='software-funfact'> . </div>
                        </div>
                        <div className="software-about">
                            <div className="software-name">Mihir Mishra</div>
                            <div className="software-info">Computer Science<br />2026</div>
                        </div>
                    </div>
                    <div className="software-member">
                        <div className="photo"><img alt="member" src={dSerena} class='image' /></div>
                        <div class='software-middle'>
                            <div class='software-funfact'> . </div>
                        </div>
                        <div className="software-about">
                            <div className="software-name">Serena Duncan</div>
                            <div className="software-info">Computer Science<br />2026</div>
                        </div>
                    </div>
                    <div className="software-member">
                        <div className="photo"><img alt="member" src={cSia} class='image' /></div>
                        <div class='software-middle'>
                            <div class='software-funfact'> . </div>
                        </div>
                        <div className="software-about">
                            <div className="software-name">Sia Chitnis</div>
                            <div className="software-info">Computer Science<br />2026</div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Software
