import React from 'react'
import './mechanical.css'
import mechSubteam from './img/roster2023/CEV_Mechanical.jpg'
import cDerek from './img/roster2023/derek_chiou.jpg'
import kMax from './img/roster2023/max_koenig.jpg'
import mRachel from './img/roster2023/rachel_mead.jpg'
import wAlejandro from './img/roster2023/alejandro_wilcox.jpg'
import lSophia from './img/roster2023/sophia_lin.jpg'
import sEthan from './img/roster2023/ethan_sarpong.jpg'
import eAva from './img/roster2023/ava_epstein.jpg'
import ySelena from './img/roster2023/selena_yao.jpg'
import oAndrew from './img/roster2023/empty-frame.png'
import pJuan from './img/roster2023/juan_pelaez.jpg'
import yKelvin from './img/roster2023/kelvin_yuan.jpg'
import cEmilio from './img/roster2023/emilio_cabellos.jpg'
import wTyler from './img/roster2023/tyler_wisniewski.jpg'
import sSebastian from './img/roster2023/empty-frame.png'
import sNitya from './img/roster2023/nitya_sharma.jpg'
import dWesley from './img/roster2023/wesley_decosta.jpg'
import sGregory from './img/roster2023/gregory_sharma.jpg'
import wAmy from './img/roster2023/empty-frame.png'
import jCashman from './img/roster2023/jamie_cashman.jpg'
import hEvelyn from './img/roster2023/evelyn_hu.jpg'
import mMack from './img/roster2023/empty-frame.png'
import iAva from  './img/roster2023/empty-frame.png'
import mAshley from './img/roster2023/ashley_miller.jpg'
import nNila from './img/roster2023/nila_narayan.jpg'
import yAdrienne from './img/roster2023/adrienne_yoon.jpg'
import yAlyssa from './img/roster2023/alyssa_yoon.jpg'
import lEvan from './img/roster2023/evan_leong.jpg'
import frame from './img/roster2023/empty-frame.png'



function Mechanical() {
    return (
        <div class="mech-background">
            <div class="mech-intro">
                Mechanical
                <br />
                <div id="space"></div>
                <img alt="mechanical subteam" src={mechSubteam} />
                <div id="space"></div>
            </div>

            <div class="mech-overview">
                The mechanical team develops the vehicle’s chassis, aerodynamic body,
                and drivetrain. This involves an annual design, analysis, manufacturing,
                and testing cycle. Recent vehicle designs have been developed using
                computer simulations, carbon fiber composites, and optimized CNC-machined
                parts.
            </div>

            <div class="mechanical-projects">
                <div class="mech-side-project">
                    <h4> </h4>
                    <h2>Autonomy</h2>
                    <p>Tests the autonomous steering mechanism in a benchtop setup. Also kicks off the design of the autonomous braking mechanism and brake dynamometer.</p>
                </div>
                <div class="mech-side-project">
                    <h4> </h4>
                    <h2>Drivetrain</h2>
                    <p>Completing the UC car, including making our first differential by hand.</p>
                </div>
                <div class="mech-side-project">
                    <h4> </h4>
                    <h2>Steering</h2>
                    <p>Work on interfacing with a rectangular front tube and finalizing the parts to put into the car.</p>
                </div>
                <div class="mech-side-project">
                    <h2>Chassis</h2>
                    <p>The main load bearing and aerodynamic structure of the vehicle. Each year, the chassis is optimized to reduce drag and weight and provide a stiff structure to which other systems mount.</p>
                </div>
            </div>

            <div class="mech-leads">
                <div class="mech-lead">
                    <div class="photo"><img alt="member" src={cDerek} class='image' /></div>

                    <div class='mech-middle'>
                        <div class='mech-funfact'>The letters in my last name are in alphabetical order.</div>
                    </div>
                    <div class="mech-about">
                        <div class="mech-name">Derek Chiou</div>
                        <div class="mech-title">Full Team Lead</div>

                        <div class="mech-info">Mechanical and Aerospace Engineering<br />2023</div>
                    </div>
                </div>


                <div class="mech-lead">
                    <div class="photo"><img alt="member" src={kMax} class='image' /></div>
                    <div class='mech-middle'>
                        <div class='mech-funfact'>I have a twin sister.</div>

                    </div>
                    <div class="mech-about">
                        <div class="mech-name">Max Koenig</div>
                        <div class="mech-title">Subteam Lead</div>
                        <div class="mech-info">Mechanical and Aerospace Engineering<br />2023</div>
                    </div>
                </div>
                <div class="mech-lead">
                    <div class="photo"><img alt="member" src={wAlejandro} class='image' /></div>
                    <div class='mech-middle'>
                        <div class='mech-funfact'></div>
                    </div>
                    <div class="mech-about">
                        <div class="mech-name">Alejandro Wilcox</div>
                        <div class="mech-title">Subteam Lead</div>
                        <div class="mech-info">Mechanical and Aerospace Engineering<br />2023</div>
                    </div>
                </div>
            </div>

            <div class="mech-subteam">
                <div class="mech-members">
                    <div class="mech-member">
                        <div class="photo"><img alt="member" src={mRachel} class='image' /></div>
                        <div class='mech-middle'>
                            <div class='mech-funfact'></div>
                        </div>
                        <div class="mech-about">
                            <div class="mech-name">Rachel Mead</div>
                            <div class="mech-info">Mechanical and Aerospace Engineering<br />2023</div>
                        </div>
                    </div>

                    <div class="mech-member">

                        <div class="photo"><img alt="member" src={sNitya} class='image' /></div>
                        <div class='mech-middle'>
                            <div class='mech-funfact'>I've seen the peak of Mt. Everest from far away.</div>
                        </div>
                        <div class="mech-about">
                            <div class="mech-name">Nitya Sharma</div>
                            <div class="mech-info">Engineering Physics<br />2024</div>
                        </div>
                    </div>
                    <div class="mech-member">
                        <div class="photo"><img alt="member" src={hEvelyn} class='image' /></div>
                        <div class='mech-middle'>
                            <div class='mech-funfact'>I want to be a chef!</div>
                        </div>
                        <div class="mech-about">
                            <div class="mech-name">Evelyn Hu</div>
                            <div class="mech-info">Mechanical and Aerospace Engineering<br />2024</div>
                        </div>
                    </div>
                    <div class="mech-member">
                        <div class="photo"><img alt="member" src={mAshley} class='image' /></div>
                        <div class='mech-middle'>
                            <div class='mech-funfact'></div>
                        </div>
                        <div class="mech-about">
                            <div class="mech-name">Ashley Miller</div>
                            <div class="mech-info">Applied and Engineering Physics<br />2024</div>
                        </div>
                    </div>

                    <div class="mech-member">
                        <div class="photo"><img alt="member" src={jCashman} class='image' /></div>
                        <div class='mech-middle'>
                            <div class='mech-funfact'></div>
                        </div>
                        <div class="mech-about">
                            <div class="mech-name">Jamie Cashman</div>
                            <div class="mech-info">Mechanical and Aerospace Engineering<br />2024</div>
                        </div>
                    </div>
                    <div class="mech-member">
                        <div class="photo"><img alt="member" src={sSebastian} class='image' /></div>
                        <div class='mech-middle'>
                            <div class='mech-funfact'></div>
                        </div>
                        <div class="mech-about">
                            <div class="mech-name">Sebastian Salgado</div>
                            <div class="mech-info">Mechanical and Aerospace Engineering<br />2024</div>
                        </div>
                    </div>
                    <div class="mech-member">
                        <div class="photo"><img alt="member" src={nNila} class='image' /></div>
                        <div class='mech-middle'>
                            <div class='mech-funfact'></div>
                        </div>
                        <div class="mech-about">
                            <div class="mech-name">Nila Narayan</div>
                            <div class="mech-info">Mechanical and Aerospace Engineering<br />2024</div>
                        </div>
                    </div>


                    <div class="mech-member">
                        <div class="photo"><img alt="member" src={yAdrienne} class='image' /></div>
                        <div class='mech-middle'>
                            <div class='mech-funfact'>I don't like french fries.</div>
                        </div>
                        <div class="mech-about">
                            <div class="mech-name">Adrienne Yoon</div>
                            <div class="mech-info">Mechanical and Aerospace Engineering<br />2025</div>
                        </div>
                    </div>
                    <div class="mech-member">
                        <div class="photo"><img alt="member" src={yAlyssa} class='image' /></div>
                        <div class='mech-middle'>
                            <div class='mech-funfact'>My favorite wings over flavor is lemon pepper.</div>
                        </div>
                        <div class="mech-about">
                            <div class="mech-name">Alyssa Yoon</div>
                            <div class="mech-info">Mechanical and Aerospace Engineering<br />2025</div>
                        </div>
                    </div>
                    <div class="mech-member">
                        <div class="photo"><img alt="member" src={sEthan} class='image' /></div>
                        <div class='mech-middle'>
                            <div class='mech-funfact'>I climbed the Eiffel tower at the age of around 10.</div>
                        </div>
                        <div class="mech-about">
                            <div class="mech-name">Ethan Sarpong</div>
                            <div class="mech-info">Mechanical and Aerospace Engineering<br />2025</div>
                        </div>
                    </div>
                    <div class="mech-member">
                        <div class="photo"><img alt="member" src={lEvan} class='image' /></div>
                        <div class='mech-middle'>
                            <div class='mech-funfact'>I'm banned from Twitter, WeChat and Facebook Messenger...</div>
                        </div>
                        <div class="mech-about">
                            <div class="mech-name">Evan Leong</div>
                            <div class="mech-info">Computer Science<br />2025</div>
                        </div>
                    </div>
                    <div class="mech-member">
                        <div class="photo"><img alt="member" src={lSophia} class='image' /></div>
                        <div class='mech-middle'>
                            <div class='mech-funfact'>I like to practice flow arts (it's like fire spinning but with LEDs).</div>
                        </div>
                        <div class="mech-about">
                            <div class="mech-name">Sophia Lin</div>
                            <div class="mech-info">Mechanical and Aerospace Engineering<br />2025</div>
                        </div>
                    </div>
                    <div class="mech-member">
                        <div class="photo"><img alt="member" src={pJuan} class='image' /></div>
                        <div class='mech-middle'>
                            <div class='mech-funfact'>I have lived in three different countries during the past two years.</div>
                        </div>
                        <div class="mech-about">
                            <div class="mech-name">Juan Pelaez</div>
                            <div class="mech-info">Mechanical and Aerospace Engineering<br />2025</div>
                        </div>
                    </div>
                    <div class="mech-member">
                        <div class="photo"><img alt="member" src={eAva} class='image' /></div>
                        <div class='mech-middle'>
                            <div class='mech-funfact'>My go-to CTB order is the Zabs.</div>
                        </div>
                        <div class="mech-about">
                            <div class="mech-name">Ava Epstein</div>
                            <div class="mech-info">Mechanical and Aerospace Engineering<br />2025</div>
                        </div>
                    </div>
                    <div class="mech-member">
                        <div class="photo"><img alt="member" src={wTyler} class='image' /></div>
                        <div class='mech-middle'>
                            <div class='mech-funfact'>My favorite ice cream flavor is chocolate chip cookie dough.</div>
                        </div>
                        <div class="mech-about">
                            <div class="mech-name">Tyler Wisniewski</div>
                            <div class="mech-info">Mechanical and Aerospace Engineering<br />2025</div>
                        </div>
                    </div>
                    <div class="mech-member">
                        <div class="photo"><img alt="member" src={wAmy} class='image' /></div>
                        <div class='mech-middle'>
                            <div class='mech-funfact'></div>
                        </div>
                        <div class="mech-about">
                            <div class="mech-name">Amy Wang</div>
                            <div class="mech-info">Mechanical and Aerospace Engineering<br />2026</div>
                        </div>
                    </div>
                    <div class="mech-member">
                        <div class="photo"><img alt="member" src={oAndrew} class='image' /></div>
                        <div class='mech-middle'>
                            <div class='mech-funfact'>If given the option, I'd fight a bear.</div>
                        </div>
                        <div class="mech-about">
                            <div class="mech-name">Andrew D'Onforio</div>
                            <div class="mech-info">Mechanical Engineering<br />2026</div>
                        </div>
                    </div>
                    <div class="mech-member">
                        <div class="photo"><img alt="member" src={cEmilio} class='image' /></div>
                        <div class='mech-middle'>
                            <div class='mech-funfact'>I used to drive between Mexico and the U.S. every day to go to school.</div>
                        </div>
                        <div class="mech-about">
                            <div class="mech-name">Emilio Ceballos</div>
                            <div class="mech-info">Mechanical Engineering<br />2026</div>
                        </div>
                    </div>
                    <div class="mech-member">
                        <div class="photo"><img alt="member" src={sGregory} class='image' /></div>
                        <div class='mech-middle'>
                            <div class='mech-funfact'>I can do a standing backflip :D</div>
                        </div>
                        <div class="mech-about">
                            <div class="mech-name">Gregory Sharma</div>
                            <div class="mech-info">Mechanical Engineering<br />2026</div>
                        </div>
                    </div>
                    <div class="mech-member">
                        <div class="photo"><img alt="member" src={mMack} class='image' /></div>
                        <div class='mech-middle'>
                            <div class='mech-funfact'></div>
                        </div>
                        <div class="mech-about">
                            <div class="mech-name">Mackemey Munion</div>
                            <div class="mech-info">Mechanical Engineering<br />2026</div>
                        </div>
                    </div>
                    <div class="mech-member">
                        <div class="photo"><img alt="member" src={ySelena} class='image' /></div>
                        <div class='mech-middle'>
                            <div class='mech-funfact'>I draw comics!</div>
                        </div>
                        <div class="mech-about">
                            <div class="mech-name">Selena Yao</div>
                            <div class="mech-info">Mechanical and Aerospace Engineering<br />2026</div>
                        </div>
                    </div>
                    <div class="mech-member">
                        <div class="photo"><img alt="member" src={dWesley} class='image' /></div>
                        <div class='mech-middle'>
                            <div class='mech-funfact'>I watched the entire Avatar the Last Airbender series in 3 days.</div>
                        </div>
                        <div class="mech-about">
                            <div class="mech-name">Wesley Decosta</div>
                            <div class="mech-info">Mechanical Engineering<br />2026</div>
                        </div>
                    </div>
                    
                    <div class="mech-member">
                        <div class="photo"><img alt="member" src={yKelvin} class='image' /></div>
                        <div class='mech-middle'>
                            <div class='mech-funfact'>I'm in Spider-Man Far From Home.</div>
                        </div>
                        <div class="mech-about">
                            <div class="mech-name">Kelvin Yuan</div>
                            <div class="mech-info">Mechanical and Aerospace Engineering<br />2026</div>
                        </div>
                    </div>
                    <div class="mech-member">
                        <div class="photo"><img alt="member" src={iAva} class='image' /></div>
                        <div class='mech-middle'>
                            <div class='mech-funfact'></div>
                        </div>
                        <div class="mech-about">
                            <div class="mech-name">Ava Ianuale</div>
                            <div class="mech-info">Materials Science and Engineering<br />2026</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Mechanical
