import React from 'react'
import './homenew.css'


function HomeNew() {
    return (


       <div className='wholepage'>
        <head>
            <meta name="description" content="Cornell Electric Vehicles is the foremost project team at Cornell University dedicated to building autonomous, hyper-efficient electric cars."></meta>
        </head>
        <div className='pic'>
            
        </div>
            
            <div className='home-title'>
                CORNELL ELECTRIC VEHICLES
            </div>
            {/* <div className='home-description'>
                The foremost project team at Cornell University dedicated to building autonomous, hyper-efficient electric cars
            </div> */}

            <div class="container">
                <div class="vertical-center">
                <a href="https://www.cornellelectricvehicles.org/">
                    <button type="button" class="button">
                        Drive to Our New Site
                    </button>
                </a>
                </div>
            </div>

       </div>

    )
}

export default HomeNew
