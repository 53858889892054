import React from 'react'
import './team.css'
import { Link } from 'react-router-dom'
import teamPhoto from './img/roster2023/CEV_FullTeam.jpg'

function Team() {

    return (
        <div className="team-page">
            <div class="team-photo">
                <strong>The Team</strong>
                <img src={teamPhoto} alt="team" />
            </div>

            <div class='team-names'>
                <Link to="/team/leads" style={{ textDecoration: 'none' }}>leads</Link>
                <Link to="/team/electrical" style={{ textDecoration: 'none' }}>electrical</Link>
                <Link to="/team/mechanical" style={{ textDecoration: 'none' }}>mechanical</Link>
                <Link to="/team/software" style={{ textDecoration: 'none' }}>software</Link>
                <Link to="/team/operations" style={{ textDecoration: 'none' }}>operations</Link>
                <Link to="/team/platform" style={{ textDecoration: 'none' }}>platform</Link>
                {/* <Link to="/team/alumni" style={{ textDecoration: 'none' }}>alumni</Link> */}
            </div>
        </div>

    )
}

export default Team