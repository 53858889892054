import React from 'react'
import './competition.css'
import shellmar from './img/about/shell.JPG'
import inter from './img/about/inter.png'
import comp from './img/about/comp.png'
import rules from './img/about/rules.png'

function Competition() {
    return (
        <div className="about-background">
            <div className="about-intro">
                <strong>Shell Eco-Marathon</strong>
            </div>

            <div className="about-blocks">
                <div id="block1">
                    For Spring 2023, Cornell aims to compete in the Shell
                    Eco-Marathon Urban Concept category using Battery-Electric
                    energy.
                </div>
                <div id="block2">
                    The Urban Concept category requires all vehicles to possess
                    familiar modern passenger car features such as having 4
                    wheels, 2 side doors, a windshield with effective wipers,
                    and a space for luggage.

                </div>
                <div id="block3">
                    The winner of the competition is the team with the highest
                    energy efficiency result in their vehicle class and energy
                    category.
                </div>
            </div>

            <div id="shell">
                <img src={shellmar} alt="shell-mar" />
            </div>

            <div className="awards">
                <strong>Awards</strong>
            </div>

            <ul className="awardNames">
                <div className="award">
                    <li><strong>Safety Off-Track Award - <em>2021</em></strong></li>
                    <em>Winner</em>
                    <div className="award-des">
                        An excellent and well laid out report demonstrating a very robust safety culture.
                        The team described a very effective adaptation to the challenges COVID-19 presented as well
                        as covering general behaviors, ways of working and innovative design.
                    </div>
                </div>

                <div className="award">
                    <li><strong>Data and Telemetry Award - <em>2021</em></strong></li>
                    <em>Runner Up</em>
                    <div className="award-des">
                        A clever system that incorporates efficient data collection, real-time vehicle
                        telemetry with online speed profile optimization, driver dashboarding and a
                        web-based live data review and analysis platform.
                    </div>
                </div>

                <div className="award">
                    <li><strong>Shell Eco-Marathon - <em>2019</em></strong></li>
                    <em>3rd Place</em>
                    <div className="award-des">
                        The Kiwi Kruiser competed in the Americas region Prototype
                        category using Battery-Electric energy. The car reached
                        an energy efficiency of 139.9 m/kWh.
                    </div>
                </div>

                <div className="award">
                    <li><strong>Shell Eco-Marathon - <em>2018</em></strong></li>
                    <em>5th Place</em>
                    <div className="award-des">
                        The CRR18 competed in the Americas region Prototype
                        category using Battery-Electric energy. The car reached
                        an energy efficiency of 167.4 m/kWh.
                    </div>
                </div>
            </ul>

            <div className="about-columns">
                <div className="about-col">
                    <img src={inter} alt="inter" />
                    <div className="under">________________________</div>
                    <div className="about-text">
                        Our unique projects incorporate and integrate analog and
                        power systems, digital and microcontroller design, mechanical
                        design, and chemical design. Experience in all of these
                        disciplines is highly relevant to the growing fields of
                        power storage, electric drives, and energy management.
                    </div>
                    <div className="under">________________________</div>
                </div>
                <div className="about-col">
                    <img src={comp} alt="comp" />
                    <div className="under">________________________</div>
                    <div className="about-text">
                        The <a href="https://www.shell.com/make-the-future/shell-ecomarathon.html" target="_blank" rel="noopener noreferrer" >Shell Eco-Marathon</a> is
                        a competition of ultimate fuel efficiency. Teams can
                        power their vehicles with gasoline, diesel, liquid fuel,
                        hydrogen fuel cells, or batteries. Each team
                        travels a set distance using the least amount of energy
                        possible and competes against other project teams from
                        across the country.
                    </div>
                    <div className="under">________________________</div>
                </div>
                <div className="about-col">
                    <img src={rules} alt="rules" />
                    <div className="under">________________________</div>
                    <div className="about-text">
                        Competitors are judged based on how much energy the
                        vehicle uses during its best run. In 2022, the vehicle
                        must make 4 laps of the track in a maximum of 40 minutes
                        for a total of 10 miles. There is a maximum of 5 attempts
                        and our best result will be retained for the final
                        classification.
                    </div>
                    <div className="under">________________________</div>
                </div>
            </div>
        </div>
    )
}

export default Competition
