import React from 'react'
import './botInfo.css'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import instagram from './img/bottominfo/instagram.png'
import facebook from './img/bottominfo/facebook.png'
import email from './img/bottominfo/email.png'
import fullCevLight from './img/logos/full-cev-dark.png'

function BottomInfo() {

    let opaque = (useLocation().pathname !== "///")

    if (opaque === true) {
        return (
            <div>
                <div className="bot-info">
                    <div className='bot-words'>
                        <div className='bot-about'>
                            <h1><strong>We Are</strong></h1>
                            Founded in 2014, Cornell Electric Vehicles is the first
                            project team at Cornell University dedicated to designing
                            completely electric vehicles. We are an interdisciplinary
                            team that provides students the opportunity to apply
                            in-class knowledge to modern issues in engineering such
                            as sustainable design and electric mobility.
                        </div>
                        <div className='bot-links'>
                            <h2><strong>Quick Links</strong></h2>
                            <Link to="/">Home</Link><br />
                            <Link to="/competition">Competition</Link><br />
                            <Link to="/team">Team</Link><br />
                            <Link to="/vehicles">Vehicles</Link><br />
                            <Link to="/recruitment">Recruitment</Link><br />
                            <Link to="/sponsors">Sponsors</Link>
                        </div>
                        <div className='bot-contact'>
                            <h2><strong>Contact Us</strong></h2>
                            <div className="bot-email">
                                <a href="mailto: cornellresistance@gmail.com" target="_blank" rel="noopener noreferrer" ><img src={email} alt='email' /></a>
                            </div>
                            <h2><strong>Socials</strong></h2>

                            <div class="bot-socials">
                                <div id="instagram">
                                    <a href="https://www.instagram.com/cornellelectricvehicles/" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="instagram" /></a>
                                </div>
                                <div id="facebook">
                                    <a href="https://www.facebook.com/CornellElectricVehicles/" target="_blank" rel="noopener noreferrer" ><img src={facebook} alt="facebook" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bot-logo">
                    <img src={fullCevLight} alt="cev" />
                </div>
            </div>
        )
    }
    else {
        return <></>
    }
}

export default BottomInfo
