import React from 'react'
import './platform.css'
import platformSubteam from './img/subteams/ops-photo.png'
import oChuhan from './img/roster2023/chuhan_ouyang.jpg'
import kAri from './img/roster2023/ari_kapelyan.jpg'
import frame from './img/roster2023/empty-frame.png'

function Platform() {
    return (
        <div className="platform-background">
            <div class="platform-intro">
                Platform
                <br />
                <div id="space"></div>
                    <img alt="platform subteam" src={platformSubteam} />
                <div id="space"></div>
            </div>

            <div class="platform-overview">
            The newly established Platform subteam seeks to compliment CEV’s transition to self-driving by developing dedicated compute platforms for autonomy. 
            </div>
            
            <div class="platform-description">
                    <h2>What We Do:</h2>
                    {/* <p>Maintaisns our website constantly with team updates.</p> */}
                    <p>Currently, our compute platforms are general-purpose, power-intensive, and heavy. This subteam will address these shortcomings, and investigate the burgeoning possibilities of heterogeneous computing, leveraging industry FPGA SoCs. We hope to develop and integrate optimized compute platforms to improved autonomy capabilities and achieve research outcomes under the guidance of faculty.
We plan to use a mix the traditional CPU computing platform with FPGA to take advantage of its flexibility and parallelism.  FPGA’s flexibility allow us to iteratively improve our self-driving algorithms and easily synthesize new design. FPGA’s parallel computing capabilities allow us to accelerate large-scale operations, such as image processing, and assign independent tasks to dedicated hardware sections. Moreover, FPGA’s cutsomizable I/O blocks allow us to interface our computing platform with external cameras and sensors necessary for real-time self driving systems.
                    </p>  
            </div>

            <div class="platform-members">
                <div class="platform-lead">
                    <div class="photo"><img alt="member" src={oChuhan} class='image' /></div>
                    <div class='platform-middle'>
                        <div class='platform-funfact'>...</div>
                    </div>
                    <div class="platform-about">
                        <div class="platfom-name">Chuhan Ouyang</div>
                        <div class="platform-title">Subteam Lead</div>
                        <div class="platform-info">Computer Science<br/>2025</div>
                    </div>
                </div>
                <div class="platform-lead">
                    <div class="photo"><img alt="member" src={kAri} class='image' /></div>
                    <div class='platform-middle'>
                        <div class='platform-funfact'></div>
                    </div>
                    <div class="platform-about">
                        <div class="platfrorm-name">Ari Kapelyan</div>
                        <div class="platform-title">Subteam Lead</div>
                        <div class="platform-info">Electrical and Computing Engineering<br/>2025</div>
                    </div>
                </div>
                <div class="platform-lead">
                    <div class="photo"><img alt="member" src={frame} class='image' /></div>
                    <div class='platform-middle'>
                        <div class='platform-funfact'></div>
                    </div>
                    <div class="platform-about">
                        <div class="platfrorm-name">Howard Hua</div>
                        <div class="platform-title">Subteam Lead</div>
                        <div class="platform-info"><br/>202X</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Platform
