import React from 'react'
import './vehicles.css'
import crr from './img/vehicles/crr.jpg'
import kiwi from './img/vehicles/kiwi.jpg'
import cuckoo_caravan from './img/vehicles/cuckoo.jpg'
import cuckoo_caravan2 from './img/vehicles/cuckoo_caravan2.jpeg'

function Vehicles() {
    return (
        <div className="vehicles-background">
            <div className="vehicles-intro">
                <strong>Our Vehicles</strong>
            </div>

            <div className="vehicles-current">
                <div className="vehicles-current-name">
                    CURRENT UPDATES
                </div>
                <div className="vehicles-row">
                    <div className="vehicles-column">
                        After competing in the 2022 Shell Eco-Marathon, the team
                        is now transitioning and starting to work on our new Urban
                        Concept vehicle for our next competition in Spring 2023.
                    </div>
                    <div className="vehicles-column">
                        <img src={cuckoo_caravan2} alt="Cuckoo Caravan2" />
                    </div>
                </div>
            </div>

            <div className="vehicles-timeline-name">
                VEHICLE HISTORY
            </div>


            <div className="vehicles-timeline">
                <div className="vehicles-container left">
                    {/* <div className="vehicles-date">
                        2022
                    </div> */}
                    <div className="vehicles-content">
                        <h3>2022</h3>
                        <h2>Cuckoo Caravan</h2>
                        <figure>
                            <img src={cuckoo_caravan} alt="Cuckoo Caravan" />
                        </figure>
                        <div className="vehicles-category">
                            Weight<br />Chassis<br />Efficiency<br />Motor<br />Battery
                        </div>
                        <div className="vehicles-des">
                            37 kg<br />Carbon Fiber Monocoque<br />Coming Soon<br />24V BLDC<br />10000 mAH 6S 22.2V 12C
                        </div>
                    </div>
                </div>


                <div className="vehicles-container right">
                    {/* <div className="vehicles-date">
                        2019
                    </div> */}

                    <div className="vehicles-content">
                        <h3>2019</h3>
                        <h2>Kiwi Kruiser</h2>
                        <figure>
                            <img src={kiwi} alt="Kiwi Kruiser" />
                        </figure>
                        <div className="vehicles-category">
                            Weight<br />Chassis<br />Efficiency<br />Motor<br />Battery
                        </div>
                        <div className="vehicles-des">
                            35 kg<br />Steel tube frame<br />5780 MPGe<br />48V BLDC Outrunner<br />Two 4000 mAH 22.3V 6s Li-po batteries
                        </div>
                    </div>
                </div>


                <div className="vehicles-container left">
                    {/* <div className="vehicles-date">
                        2018
                    </div> */}
                    <div className="vehicles-content">
                        <h3>2018</h3>
                        <h2>CRR18</h2>
                        <figure>
                            <img src={crr} alt="CRR18" />
                        </figure>
                        <div className="vehicles-category">
                            Weight<br />Chassis<br />Efficiency<br />Motor<br />Battery
                        </div>
                        <div className="vehicles-des">
                            35 kg<br />Steel tube frame<br />5780 MPGe<br />48V BLDC Outrunner<br />Two 4000 mAH 22.3V 6s Li-po batteries
                        </div>
                    </div>
                </div>
            </div>


        </div >
    )
}

export default Vehicles
