import React from 'react'
import './operations.css'
import opsPhoto from './img/subteams/ops-photo.png'
import opsSubteam from './img/roster2023/CEV_Ops.jpg'
import fSofia from './img/roster2023/sofia_florez.jpg'
import hYen from './img/roster2023/hanna_yen.jpg'
import sKate from './img/roster2023/kate_sawin.jpg'
import zJoanna from './img/roster2023/joanna_zhang.jpg'
import cEdward from './img/roster2023/empty-frame.png'
import bAnay from './img/roster2023/anay_bhat.jpg'
import rKevin from './img/roster2023/kevin_ram.jpg'
import pLeo from './img/roster2023/leo_phillips.jpg'
import zMo from './img/roster2023/mo_zaib.jpg'
import frame from './img/roster2023/empty-frame.png'
import LL from './img/logos/LinkedinLogo.png'


function Operations() {
    return (
        <div className="ops-background">
            <div class="ops-intro">
                Operations
                <br />
                <div id="space"></div>
                <img alt="operations subteam" src={opsSubteam} />
                <div id="space"></div>
            </div>

            <div class="ops-overview">
                The Operations subteam combines elements of technology and business to ensure that the team's goals are achieved.
                Operations is the connection between the team and the Cornell community.
                The team is responsible for front-end development, budgeting, marketing and branding, sponsor relations, and outreach events.
            </div>

            <div class="operations-projects">
                <div class="ops-side-project">
                    <h4> </h4>
                    <h2>Website</h2>
                    {/* <p>Maintains our website constantly with team updates.</p> */}
                    <p>Responsible for the design and construction of the website, ensuring that the website is constantly updated.
                        Oversees the website functionality and evaluates website performance.
                    </p>
                </div>
                <div class="ops-side-project">
                    <h4> </h4>
                    <h2>Public Relations</h2>
                    {/* <p>Manages social media and publicizes crowdfunding events.</p> */}
                    <p>Responsible for building and maintaining a positive image for CEV.
                        Manages social media and publicizes crowdfunding events to maintain
                        public interest.
                    </p>
                </div>
                <div class="ops-side-project">
                    <h4> </h4>
                    <h2>Sponsor Relations</h2>
                    {/* <p>Reaches out to potential sponsors and maintains contact with current ones.</p> */}
                    <p>Foster relationships with current sponsors while cultivating links with new sponsors and organizations.</p>
                </div>
                <div class="ops-side-project">
                    <h4> </h4>
                    <h2>Graphic and Video Design</h2>
                    {/* <p>Creates graphics and videos for our branding and recruitment.</p> */}
                    <p>Develops visual concepts for branding, marketing, and promotional purposes via Adobe Illustrator and Adobe Photoshop. </p>
                </div>
                <div class="ops-side-project">
                    <h4> </h4>
                    <h2>Finance</h2>
                    {/* money person*/}
                    <p>Conduct financial reviews, prepare budget reports, confirm team purchases, and evaluate budget to determine optimal allocation of project funds.</p>
                </div>
            </div>

            <div class="ops-leads">
                <div class="ops-lead">
                    <div class="photo"><img alt="member" src={fSofia} class='image' /></div>
                    <div class='ops-middle'>
                        <div class='ops-funfact'>[Refer to Hanna's fun fact]</div>
                    </div>
                    <div class="ops-about">
                        <div class="ops-name">Sofia Florez</div>
                        <div class="ops-title">Full Team Lead</div>
                        <div class="ops-info">Operations Research<br />2023</div>
                    </div>
                </div>
                <div class="ops-lead">
                    <div class="photo"><img alt="member" src={sKate} class='image' /></div>
                    <div class='ops-middle'>
                        <div class='ops-funfact'>"I can scuba dive!"</div>
                    </div>
                    <div class="ops-about">
                        <div class="ops-name">Kate Sawin</div>
                        <div class="ops-title">Subteam Lead</div>
                        <div class="ops-info">Operations Research<br />2024</div>
                    </div>
                </div>
                <div class="ops-lead">
                    <div class="photo"><img alt="member" src={hYen} class='image' /></div>
                    <div class='ops-middle'>
                        <div class='ops-funfact'>[Insert Zoe's fun fact here]</div>
                    </div>
                    <div class="ops-about">
                        <div class="ops-name">Hanna Yen</div>
                        <div class="ops-title">Co-Subteam Lead</div>
                        <div class="ops-info">Operations Research<br />2023</div>
                    </div>
                </div>
            </div>


            <div class="ops-subteam">
                <div class="ops-members">
                    <div class="ops-member">
                        <div class="photo"><img alt="member" src={zJoanna} class='image' /></div>
                        <div class='ops-middle'>
                            <div class='ops-funfact'>My middle name is A. That's it. Just A.</div>

                        </div>
                        <div class="ops-about">
                            <div class="ops-name">Joanna Zhang</div>
                            <div class="ops-info">Computer Science<br />2024</div>
                        </div>
                    </div>
                    <div class="ops-member">
                        <div class="photo"><img alt="member" src={zMo} class='image' /></div>
                        <div class='ops-middle'>
                            <div class='ops-funfact'>I can pinpoint every single country on an empty map.</div>
                        </div>
                        <div class="ops-about">
                            <div class="ops-name">Muhammad Zaib</div>
                            <div class="ops-info">Industrial and Labor Relations<br />2025</div>
                        </div>
                    </div>

                    <div class="ops-member">
                        <div class="photo"><img alt="member" src={rKevin} class='image' /></div>
                        <div class='ops-middle'>
                            <div class='ops-funfact'>I was flown out by Disney to Florida to compete in Nationals for SSBU.</div>
                        </div>
                        <div class="ops-about">
                            <div class="ops-name">Kevin Ram</div>
                            <div class="ops-info">Computer Science<br />2026</div>
                            <a href="https://www.linkedin.com/in/kevinmram/">
                                {/* <img src={LL} alt="LinkedIn Logo" width="1" height="1" /> */}
                                <img style={{ alignSelf: 'center', width: 60, height: 50 }} src={LL} alt="LinkedIn Logo" />
                            </a>
                        </div>
                    </div>
                    <div class="ops-member">
                        <div class="photo"><img alt="member" src={pLeo} class='image' /></div>
                        <div class='ops-middle'>
                            <div class='ops-funfact'></div>
                        </div>
                        <div class="ops-about">
                            <div class="ops-name">Leonara Phillips</div>
                            <div class="ops-info">Operations Research<br />2026</div>
                        </div>
                    </div>
                    <div class="ops-member">
                        <div class="photo"><img alt="member" src={bAnay} class='image' /></div>
                        <div class='ops-middle'>
                            <div class='ops-funfact'>I love ranch.</div>
                        </div>
                        <div class="ops-about">
                            <div class="ops-name">Anay Bhat</div>
                            <div class="ops-info">Operations Research<br />2026</div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Operations
